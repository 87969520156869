<template>
  <div>
    <b-row>
      <b-col md="5">
        <b-card no-body>
          <b-card-header>
            <!-- title and subtitle -->
            <div>
              <b-card-title class="mb-1">
                Uyum  Alacak Listesi
              </b-card-title>
              <b-card-sub-title>Satır Üzerine Çift Tıklayarak Detayları Görebilirsiniz</b-card-sub-title>
            </div>
            <!--/ title and subtitle -->

            <!-- badge -->
            <div class="d-flex align-items-center flex-wrap mt-sm-0 mt-1" />
            <!--/ badge -->
          </b-card-header>

          <b-card-body>
            <b-table
              small
              :fields="debtTable.fields"
              :items="data"
              selectable
              responsive="sm"
              select-mode="single"
              @row-selected="dataSelected"
            >
              <template #cell(total_due)="data">
                <strong>  {{ $staticParams.formatCurrency(data.item.total_due) }}</strong>
              </template>
              <!-- A custom formatted column -->
              <template #cell(time)="data">
                {{ data.value+' DK.' }}
              </template>

              <!-- Optional default data cell scoped slot -->
              <template #cell()="data">
                {{ data.value }}
              </template>
            </b-table>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col md="7">
        <customer-debts
          v-if="seletedData"
          :useruyumcode="seletedData.representative_uyum_code"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCard, BCardBody, BCardHeader, BCardTitle, BCardSubTitle, BTable, BRow, BCol, VBModal,
} from 'bootstrap-vue'
import customerDebts from './CustomerDebts.vue'

export default {
  components: {
    BCardHeader,
    BCard,
    customerDebts,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    BTable,
    BRow,
    BCol,
  },
  directives: {
    'b-modal': VBModal,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    title: {
      type: String,
      default: '',
    },
    subtitle: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      debtTable: {
        fields: [
          { key: 'representative_name', label: 'Temsilci' },
          // A regular column
          { key: 'total_count', label: 'Toplam Firma' },
          { key: 'total_due', label: 'Toplam Alacak' },
        ],
      },
      trainingUserList: null,
      seletedData: null,
    }
  },
  methods: {

    dataSelected(item) {
      // eslint-disable-next-line prefer-destructuring
      this.seletedData = item[0]
      // eslint-disable-next-line prefer-destructuring
    },
  },
}
</script>
