<template>
  <div>

    <b-card no-body>
      <b-card-header>
        <!-- title and subtitle -->
        <div>
          <b-card-title class="mb-1">
            Müşteri Geçmiş Alacak
          </b-card-title>
          <b-card-sub-title />
        </div>
        <!--/ title and subtitle -->

        <!-- badge -->
        <div class="d-flex align-items-center flex-wrap mt-sm-0 mt-1">
          <h5 class="font-weight-bolder mb-0 mr-1" />

        </div>
      <!--/ badge -->
      </b-card-header>

      <b-card-body>

        <!-- table -->
        <b-table-simple
          hover
        >
          <b-thead>
            <b-tr>

              <b-th>
                Müşteri Adı
              </b-th>
              <b-th>
                Toplam Borç
              </b-th>
              <b-th>
                Toplam Alacak
              </b-th>
              <b-th>
                Bakiye
              </b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr>
              <b-td>
                {{ debtData.customer_name }}
              </b-td>

              <b-td>
                {{ $staticParams.formatCurrency(debtData.total_debt) }}
              </b-td>

              <b-td>
                {{ $staticParams.formatCurrency(debtData.total_due) }}
              </b-td>
              <b-td>
                {{ $staticParams.formatCurrency(debtData.balance_due) }}
              </b-td>
            </b-tr>
          </b-tbody>

        </b-table-simple>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BCardBody, BCardHeader, BCardTitle, BCardSubTitle, BTableSimple, BThead, BTbody, BTr, BTd, BTh,
} from 'bootstrap-vue'
import store from '@/store'

export default {
  components: {
    BCardHeader,
    BCard,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    BTableSimple,
    BThead,
    BTbody,
    BTr,
    BTd,
    BTh,
  },
  props: {
    useruyumcode: {
      type: String,
      default: '',
    },
    customerName: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      debtData: null,
    }
  },
  computed: {

  },
  watch: {
    useruyumcode() {
      this.fetchData()
    },
  },
  created() {
    this.fetchData()
  },
  methods: {

    fetchData() {
      store
        .dispatch('app-reports/fetchCustomerOldDebts', {
          representative_uyum_code: this.useruyumcode,
          customer_name: this.customerName,
        })
        .then(response => {
          this.debtData = response.data.data
        })
        .catch(() => {
        })
    },

  },

}
</script>
